export function checkSortBy(filters){
  const preparedFilters = [
    filters.filter.groupBy,
    filters.filter.secondGroupBy,
    filters.filter.thirdGroupBy,
    filters.filter.fourthGroupBy
  ]
  if(!preparedFilters.some(f => ['date', 'week', 'month'].includes(f))) return
  const chosenDateGroupBy = preparedFilters.includes('date')
    ? 'date' : preparedFilters.includes('week') ? 'week' : 'month'
  filters.sortBy = [`groups.${chosenDateGroupBy}`]
  filters.sortDesc = [false]
  return {
    sortBy: filters.sortBy,
    sortDesc: filters.sortDesc
  }
}